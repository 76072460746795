/**
 * This contains the functions needed to build the config file for downloading the df excel spreadsheet.
 */
import { currentYear } from "@/functions/utils.js";

export const getDfCourses = ({
  courses,
  currentQuarter,
}) => {
  const thisQ = `Q${currentQuarter}`;
  return Object.values(courses)
    .filter((course) => course.schoolYear === currentYear)
    .filter((course) => typeof course.letterGrade[thisQ] === "string")
    .filter(
      (course) =>
        course.letterGrade[thisQ].startsWith('D') || // D,D+,D- etc.
        course.letterGrade[thisQ] === 'F'
    );
};

const getDfList = ({ data, currentQuarter }) => {
  const courses = data
    .map((student) =>
      getDfCourses({
        courses: student.courses,
        currentQuarter,
      }).map((course) => ({
        studentNumber: student.studentDistrictId,
        studentName: student._studentName,
        gradeLevel: student.currentGradeLevel,
        cohort: student.cohort,
        schoolTeam: student.schoolTeam,
        courseName: course.courseTitle,
        teacher: course.teacher.lastName,
        letterGrade: course.letterGrade[`Q${currentQuarter}`],
        numberOfDs: 0, // placeholder
        numberOfFs: 0, // placeholder
        ethnicity: student.raceAndEthnicity.race,
        gender: student.gender,
        sped: student.specialEducation ? "SpEd" : "Not SpEd",
        ell: student.englishLearner ? "ELL" : "Not ELL",
        schoolYear: course.schoolYear,
      }))
    )
    .flat();

  courses.forEach((course) => {
    course.numberOfDs = courses.filter(
      (c) => c.studentNumber === course.studentNumber && c.letterGrade.startsWith('D') // D,D+,D- etc.
    ).length;
    course.numberOfFs = courses.filter(
      (c) => c.studentNumber === course.studentNumber && c.letterGrade === 'F'
    ).length;
    delete course.schoolYear;
  });

  return courses;
};

const dfColumns = [
  { header: "Student Number", width: 15 },
  { header: "Student Name", width: 25 },
  { header: "Grade Level", width: 10 },
  { header: "Cohort", width: 10 },
  { header: "School Team", width: 20 },
  { header: "Course Name", width: 30 },
  { header: "Teacher", width: 20 },
  { header: "Letter Grade", width: 20 },
  { header: "Number of Ds", width: 15 },
  { header: "Number of Fs", width: 15 },
  { header: "Ethnicity", width: 10 },
  { header: "Gender", width: 10 },
  { header: "SpEd", width: 10 },
  { header: "ELL", width: 10 },
];

export const downloadDF = ({
  data,
  currentQuarter,
}) => {
  const rows = getDfList({ data, currentQuarter });

  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, "DF List");

  // column headers
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [dfColumns.map((col) => col.header)],
    { origin: "A1" }
  );

  // column width
  worksheet["!cols"] = dfColumns.map((col) => ({ wch: col.width }));

  // create an XLSX file and try to save to DF.xlsx
  XLSX.writeFile(workbook, "DFList.xlsx");
};
