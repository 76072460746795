/**
 * Configuration array showing how fields in the student document are grouped, for example in field selection drop-downs.
 */

export const groups = [
  {
    name: "Student Details",
    fields: [
      "counselorName",
      "currentGradeLevel",
      "cohort",
      "school.district",
      "englishLearner",
      "entryDate",
      "firstGeneration",
      "gender",
      "_myTags",
      "raceAndEthnicity.race",
      "school.name",
      "schoolTeam",
      "specialEducation",
      "email",
      "studentDistrictId",
      "_studentName",
      "freshSuccessTags",
    ],
  },
  {
    name: "On-Track",
    fields: [
      "onTrackStatus",
      "S1OnTrackStatus",
      "S2OnTrackStatus",
      "_currentQuarterOnTrackStatus",
      "Q1OnTrackStatus",
      "Q2OnTrackStatus",
      "Q3OnTrackStatus",
      "Q4OnTrackStatus",
      "_climberSlider",
      "_daysSinceLastCheckin",
    ],
  },
  {
    name: "Grade 8 Performance",
    fields: [
      "g8RiskAndOpportunityMarch.ADA",
      // "g8RiskAndOpportunityMay.ADA",
      "g8RiskAndOpportunityMarch.GPA",
      // "g8RiskAndOpportunityMay.GPA",
      "g8RiskAndOpportunityMarch.riskAndOpportunity",
      // "g8RiskAndOpportunityMay.riskAndOpportunity",
      "g8RiskAndOpportunityMarch.suspensions",
      // "g8RiskAndOpportunityMay.suspensions",
      "g8RiskAndOpportunityMarch.riskFactors",
      // "g8RiskAndOpportunityMay.riskFactors",
      "middleSchool",
    ],
  },
  {
    name: "Grade 9 Performance",
    fields: [
      "g9RiskAndOpportunity.ADA",
      "g9RiskAndOpportunity.GPA",
      "g9RiskAndOpportunity.riskAndOpportunity",
      "g9RiskAndOpportunity.suspensions",
      "g9RiskAndOpportunity.riskFactors",
    ],
  },
  {
    name: "Current Year Performance",
    fields: [
      "Q1Absences",
      "Q2Absences",
      "Q3Absences",
      "Q4Absences",
      "Q1Suspensions",
      "Q2Suspensions",
      "Q3Suspensions",
      "Q4Suspensions",
    ],
  },
  {
    name: "Academics",
    fields: [
      "ADA",
      "GPA",
      "courses",
      "graduationConcerns",
      "_onTheCusp",
      "_onTheCuspCourses",
      "_df",
      "_dfCourses",
      "OTCName",
      "passingCourseCount",
      "summerBridgeEnrollment",
      "suspensions",
      "creditsEarned",
      "recentAbsences",
    ],
  },
  {
    name: "Credits",
    fields: [
      "g8Credits",
      "g9Credits",
      "g10Credits",
      "g11Credits",
      "g12Credits",
      "creditsPredicted",
    ],
  },
  {
    name: "High School Summary",
    fields: [
      "g9ADA",
      "g9GPA",
      //   'g9Suspensions',
      "g10ADA",
      "g10GPA",
      //   'g10Suspensions',
      "g11ADA",
      "g11GPA",
      //   'g11Suspensions',
      "g12ADA",
      "g12GPA",
      //   'g12Suspensions',
    ],
  },
  {
    name: "Postsecondary",
    fields: [
      "plans",
      "alternatePlans",
      "collegeCommitment",
      "lastNavianceLogin",
      "SATScores.EBRW",
      "SATScores.Math",
      "postSecTags",
    ],
  },
  {
    name: "College Tasks",
    fields: [
      "_transcriptsSent",
      "_collegeAppsSubmitted",
      "_4YearStepsDone",
      "collegeApps",
      "summerCollegePrepInterest",
      "FAFSAIntention",
      "noFAFSAIntentionExplanation",
      "FAFSAStatus",
      "FAFSAStateStatus",
      "FSAId",
      "immunizationForm",
      "intendedMajor",
      "navianceCollegeList",
      "studentRequestedRecs",
      "teacherSubmittedRecs",
      "postSecondaryRegistration",
      "postSecondaryOrientation",
      "applicationEssay",
    ],
  },
  {
    name: "Workforce/Military Tasks",
    fields: [
      "ASVAB",
      "careerInventory",
      "employment",
      "jobApplications",
      "militaryBranchCommitment",
      "militaryRecruiter",
      "militaryBranchInterest",
      "resume",
      "sentTranscripts",
      "tradeProgramEnrollment",
      "workPermit",
    ],
  },
];
